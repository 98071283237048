import React, { useState, useEffect } from 'react';
import slider2 from '../assets/images/slider2.png';
import slider3 from '../assets/images/slider3.png';
import { Link } from 'react-router-dom';

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const images = [slider2, slider3];

  const nextSlide = () => {
    setActiveSlide((activeSlide + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); 
    return () => {
      clearInterval(interval); 
    };
  }, [activeSlide]);

  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {images.map((image, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={activeSlide === index ? 'active' : ''}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div key={index} className={`carousel-item ${activeSlide === index ? 'active' : ''}`}>
              <img src={image} className="d-block w-100 zoom-in-image" alt="..." />
              <div className="carousel-caption d-md-block ">
                <h1>Ebar Healthcare</h1>
                <p>At Ebar Healthcare, we are committed to revolutionizing the healthcare industry by developing cutting-edge medications that transform lives.</p>
                <Link to="/about" className='Rd-mr-button'>Read More</Link>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Slider;
